<div rvSpinner="user-settings" [rvShowSpinner]="loading">
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">
    <!-- App Title -->
    <h1 class="app-header-title" id="title">Users</h1>
  </div>

  <!-- body -->
  <div id="user-settings">
    <form #userForm="ngForm" id="forms.userForm" role="form" name="forms.userForm" autocomplete="on" (submit)="save()" novalidate>
      <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
        <strong>{{formError}}</strong> {{apiError}}
      </div>

      <div class="form-group required"
        [ngClass]="{ 'has-error' : username.invalid && (!username.pristine || userForm.submitted) }">
        <label>
          Username:
        </label>
        <input #username="ngModel" id="user-settings-username"
          type="email" required name="username"
          class="form-control"
          [(ngModel)]="user.username"
          [pattern]="EMAIL_REGEX"
          focusMe="true"
          />
          <p [shown]="username.invalid && (!username.pristine || userForm.submitted)"
            class="help-block validation-error-message-email">User name must be a valid email address.</p>
      </div>

      <user-fields [user]="user"></user-fields>

      <div class="button-row text-right mt-5">
        <!-- Indicates cancel or non-destructive action -->
        <button id="cancelButton" type="button" uiSref="apps.user.list" class="btn btn-default btn-toolbar">
          Cancel
        </button>
        <!-- Indicates a successful or positive action -->
        <button id="saveButton" form="forms.userForm" type="submit" class="btn btn-primary btn-toolbar" [disabled]="userForm.invalid" *requireRole="'ua sa'">
          Save
        </button>
      </div>
    </form><!-- userAdd -->
  </div>
</div><!--container-->