import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import * as _ from 'lodash';

import { ExceptionHandler } from 'src/app/ajs-upgraded-providers';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

import { ObjectHelperService } from 'src/app/shared/services/object-helper.service';
import { LocationHelperService } from 'src/app/shared/services/location-helper.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyStateService {
  static EDUCATION_INDUSTRIES = [
    'PRIMARY_SECONDARY_EDUCATION',
    'HIGHER_EDUCATION'
  ];
  static INDUSTRIAL_INDUSTRIES = [
    'CONSTRUCTION',
    'INDUSTRIAL_AUTOMATION',
    'MINING_METALS',
    'OIL_ENERGY',
    'TRANSPORTATION_TRUCKING_RAILROAD',
    'WAREHOUSING',
    'WHOLESALE'
  ];
  static DISCOUNT_INDUSTRIES = [
    'PRIMARY_SECONDARY_EDUCATION',
    'HIGHER_EDUCATION',
    'LIBRARIES',
    'PHILANTHROPY',
    'NON_PROFIT_ORGANIZATION_MANAGEMENT',
    'RELIGIOUS_INSTITUTIONS'
  ];

  pendingSelectedCompany;

  _state: any = {
    userCompany: {},
    selectedCompany: {}
  };

  constructor(
    private stateService: StateService,
    private objectHelperService: ObjectHelperService,
    private broadcasterService: BroadcasterService,
    private exceptionHandler: ExceptionHandler,
    private companyApiService: CompanyApiService,
    private locationHelperService: LocationHelperService
  ) {
    const cid = this.locationHelperService.getSearchParam('cid');
    if (cid) {
      console.debug('cid', cid,
        'saved for later processing.');
      this.pendingSelectedCompany = cid;
    }
  }

  resetCompanyState () {
    this.objectHelperService.clearObj(this._state.selectedCompany);
    this.objectHelperService.clearObj(this._state.userCompany);
    console.debug('Company state has been reset.');
  }

  init () {
    //populate userCompany
    return this.companyApiService.getCompany()
      .then((company) => {
        var selectedCompanyId = this.getSelectedCompanyId() ?
          this.getSelectedCompanyId() :
          this.pendingSelectedCompany;

        this.objectHelperService.clearAndCopy(company, this._state.userCompany);

        return this.switchCompany(selectedCompanyId)
          .catch(() => {
            if ((this.stateService.current as any).forceAuth !== false &&
              (!this.stateService.current.params || this.stateService.current.params.forceAuth !== false)) {
              this.resetCompany();
            }
          });
      })
      .catch((err) => {
        this.exceptionHandler(err, 'Failed to load user company.', true);

        throw err;
      })
      .finally(() => {
        this.pendingSelectedCompany = null;
      });
  }

  isUserCompanySelected() {
    const selectedCompany = this._state.selectedCompany || {};
    const userCompany = this._state.userCompany || {};

    return selectedCompany && selectedCompany.id === userCompany.id;
  }

  switchCompany (companyId) {
    if (companyId && companyId !== this._state.userCompany.id) {
      return this.companyApiService.getCompany(companyId)
        .then((company) => {
          this.objectHelperService.clearAndCopy(company, this._state.selectedCompany);

          this.broadcasterService.emit('risevision.company.selectedCompanyChanged');
        })
        .catch((resp) => {
          console.error('Failed to load selected company.', resp);

          return Promise.reject(resp);
        });
    } else {
      this.resetCompany();

      return Promise.resolve();
    }
  }

  reloadSelectedCompany () {
    return this.companyApiService.getCompany(this._state.selectedCompany.id)
      .then((company) => {
        this.objectHelperService.clearAndCopy(company, this._state.selectedCompany);

        this.broadcasterService.emitWithParams('risevision.company.updated', {
          'companyId': company.id
        });
      })
      .catch((resp) => {
        console.error('Failed to reload selected company.', resp);

        return Promise.reject(resp);
      });

  }

  updateCompanySettings(company) {
    if (company && company.id === this.getSelectedCompanyId()) {
      this.objectHelperService.clearAndCopy(company, this._state.selectedCompany);
    }
    if (company && company.id === this.getUserCompanyId()) {
      this.objectHelperService.clearAndCopy(company, this._state.userCompany);
    }

    this.broadcasterService.emitWithParams('risevision.company.updated', {
      'companyId': company.id
    });
  }

  resetCompany() {
    this.objectHelperService.clearAndCopy(this._state.userCompany, this._state.selectedCompany);

    this.broadcasterService.emit('risevision.company.selectedCompanyChanged');
  }

  getUserCompanyId() {
    return (this._state.userCompany && this._state.userCompany.id) || null;
  }

  getUserCompanyName() {
    return (this._state.userCompany && this._state.userCompany.name) ||
      null;
  }

  getSelectedCompanyId() {
    return (this._state.selectedCompany && this._state.selectedCompany.id) ||
      null;
  }

  getSelectedCompanyName() {
    return (this._state.selectedCompany && this._state.selectedCompany.name) ||
      null;
  }

  getSelectedCompanyCountry() {
    return (this._state.selectedCompany && this._state.selectedCompany.country) ||
      null;
  }

  getCopyOfUserCompany(noFollow?) {
    if (noFollow) {
      return _.extend({}, this._state.userCompany);
    } else {
      return this.objectHelperService.follow(this._state.userCompany);
    }
  }

  getCopyOfSelectedCompany(noFollow?) {
    if (noFollow) {
      return _.extend({}, this._state.selectedCompany);
    } else {
      return this.objectHelperService.follow(this._state.selectedCompany);
    }
  }

  isSubcompanySelected() {
    return this._state.selectedCompany && this._state.selectedCompany.id !==
      (this._state.userCompany && this._state.userCompany.id);
  }

  isRootCompany() {
    return this._state.userCompany && !this._state.userCompany.parentId;
  }

  isRootCompanySelected() {
    return this.isRootCompany() && !this.isSubcompanySelected();
  }

  isSelectedCompanyChargebee() {
    return this._state.selectedCompany && this._state.selectedCompany.origin === 'Chargebee';
  }

  isEducationCustomer(checkUserCompany?) {
    var company = checkUserCompany ? this._state.userCompany : this._state.selectedCompany;

    return company && CompanyStateService.EDUCATION_INDUSTRIES.indexOf(company.companyIndustry) !== -1;
  }

  isIndustrialCustomer(checkUserCompany?) {
    var company = checkUserCompany ? this._state.userCompany : this._state.selectedCompany;

    return company && CompanyStateService.INDUSTRIAL_INDUSTRIES.indexOf(company.companyIndustry) !== -1;
  }

  isDiscountCustomer(checkUserCompany?) {
    var company = checkUserCompany ? this._state.userCompany : this._state.selectedCompany;

    return company && CompanyStateService.DISCOUNT_INDUSTRIES.indexOf(company.companyIndustry) !== -1;
  }

  isK12Customer(checkUserCompany?) {
    var company = checkUserCompany ? this._state.userCompany : this._state.selectedCompany;

    return company && company.companyIndustry === 'PRIMARY_SECONDARY_EDUCATION';
  }

  isHigherEdCustomer(checkUserCompany?) {
    var company = checkUserCompany ? this._state.userCompany : this._state.selectedCompany;

    return company && company.companyIndustry === 'HIGHER_EDUCATION';
  }

}

angular.module('risevision.apps.services')
  .factory('companyState', downgradeInjectable(CompanyStateService));