import * as _ from 'lodash';

import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Command, CommandPaletteService } from '../../services/command-palette.service';

@Component({
  selector: 'app-command-palette',
  templateUrl: './command-palette.component.html',
  styleUrls: ['./command-palette.component.scss']
})
export class CommandPaletteComponent implements OnInit {
  @ViewChild('itemsList') itemsList!: ElementRef;

  placeholder = '';
  activeCommand?: Command = null;

  isVisible = false;
  filteredItems: { description: string }[] = [];
  selectedIndex = 0;
  userInput: string;

  otherCommandsVisible: boolean = false;

  constructor(private commandPaletteService: CommandPaletteService) {
  }

  ngOnInit(): void {
    this.commandPaletteService.loadCommandsFilterData();
  }

  updateFilteredCommands() {
    this.filterItems({ target: { value: '' } });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'L') {
      this.otherCommandsVisible = !this.otherCommandsVisible;
      this.updateFilteredCommands();
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'K') {
      this.togglePalette();
    }

    if (this.isVisible) {
      switch (event.key) {
        case 'ArrowUp':
          this.moveSelection(-1);
          break;
        case 'ArrowDown':
          this.moveSelection(1);
          break;
        case 'Enter':
          this.runSelectedItem();
          break;
        case 'Escape':
          this.closePalette();
          break;
      }
    }
  }

  togglePalette() {
    this.isVisible = !this.isVisible;

    if (this.isVisible) {
      this.placeholder = "Type a command...";
      this.updateFilteredCommands();

      setTimeout(() => {
        const input = document.getElementById('command-input');
        if (input) input.focus();
      });
    }
  }

  filterItems(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    let filteredItems = [];

    if (!this.activeCommand) {
      filteredItems = this.commandPaletteService.filterCommands(searchTerm, this.otherCommandsVisible);
    } else {
      filteredItems = this.commandPaletteService.filterFilterData(searchTerm, this.activeCommand.filterData);
    }

    this.filteredItems = filteredItems.slice(0, 200);
    this.selectedIndex = 0;

    this.userInput = event.target.value;
    this.scrollToSelectedItem();
  }

  moveSelection(direction: number) {
    this.selectedIndex = (this.selectedIndex + direction + this.filteredItems.length) % this.filteredItems.length;
    this.scrollToSelectedItem();
  }

  scrollToSelectedItem() {
    setTimeout(() => {
      const listElement = this.itemsList.nativeElement;
      const selectedElement = listElement.children[this.selectedIndex];
      if (selectedElement) {
        const listRect = listElement.getBoundingClientRect();
        const selectedRect = selectedElement.getBoundingClientRect();

        if (selectedRect.bottom > listRect.bottom) {
          listElement.scrollTop += selectedRect.bottom - listRect.bottom;
        } else if (selectedRect.top < listRect.top) {
          listElement.scrollTop -= listRect.top - selectedRect.top;
        }
      }
    });
  }

  startItem(item: { description: string }) {
    if (!this.activeCommand) {
      const command = item as Command;

      this.activeCommand = command;

      if (!command.filterData) {
        this.runCommand(this.activeCommand);
      } else {
        this.placeholder = "Type an item's name...";
        this.selectedIndex = 0;
        this.userInput = '';
        this.filteredItems = command.filterData.slice(0, 200);
      }
    } else if (!this.activeCommand.filterData) {
      this.runCommand(this.activeCommand);
    } else {
      const filterData = this.filteredItems[this.selectedIndex];

      this.activeCommand.userFilterData = filterData;
      this.runCommand(this.activeCommand);
    }
  }

  runSelectedItem() {
    if (this.activeCommand) {
      this.startItem(this.activeCommand);
    } else {
      this.runSelectedCommand();
    }
  }

  runSelectedCommand() {
    const selectedCommand = _.cloneDeep(this.filteredItems[this.selectedIndex]) as Command;

    if (selectedCommand) {
      selectedCommand.userInput = this.userInput;

      this.startItem(selectedCommand);
    }
  }

  runCommand(command) {
    console.log('Executing command:', command);

    this.closePalette();

    command.run(command);
  }

  closePalette() {
    this.userInput = '';
    this.isVisible = false;
    this.activeCommand = null;
  }
}
