<div class="command-palette-overlay" *ngIf="isVisible" (click)="closePalette()">
  <div class="command-palette" (click)="$event.stopPropagation()">
    <input
      id="filter-input"
      type="text"
      [(ngModel)]="userInput"
      (input)="filterItems($event)"
      [placeholder]="placeholder"
      autofocus
      autocomplete="off"
      focusMe="true">
    <ul #itemsList class="scrollable-list">
      @for (item of filteredItems; track item) {
        <li [class.selected]="$index === selectedIndex" (click)="startItem(item)">
          {{ item.description }}
        </li>
      }
    </ul>
  </div>
</div>
