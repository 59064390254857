import { Injectable } from '@angular/core';
import { RawParams, StateService } from '@uirouter/angular';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { EditorService } from 'src/app/editor/services/editor.service';
import { SelectCompanyComponent } from '../components/select-company/select-company.component';

import * as fuzzysort from 'fuzzysort';
import { PresentationApiService } from 'src/app/api/services/presentation-api.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { ScheduleApiService } from 'src/app/api/services/schedule-api.service';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { CompanyApiService } from 'src/app/api/services/company-api.service';

type FilterData = {
  id: string;
  description: string;
  [key: string]: any;
};

type FilterProvider = {
  init: (command: Command) => void;
  loadFilterData: (command: Command) => Promise<void>;
}

interface BaseCommand {
  name: string;
  tags?: string;
  filterData?: FilterData[];
  isAvailable?: (command: Command) => boolean;
  filterProvider?: FilterProvider;
  run: (command: Command) => void;
}

function alwaysAvailable() {
  return true;
}

export class Command implements BaseCommand {
  category: string;
  name: string;
  tags: string;
  filterData?: FilterData[];
  userInput?: string;
  userFilterData?: any;

  run: (command: Command) => void;
  isAvailable: (command: Command) => boolean;
  filterProvider?: FilterProvider;

  constructor(baseCommand: BaseCommand & { category: string }) {
    this.category = baseCommand.category;
    this.name = baseCommand.name;
    this.tags = baseCommand.tags || '';
    this.filterData = baseCommand.filterData;
    this.isAvailable = baseCommand.isAvailable || alwaysAvailable;
    this.filterProvider = baseCommand.filterProvider;
    this.run = baseCommand.run;
  }

  get description() {
    if (this.isOther()) {
      return this.name;
    } else {
      return `${this.category} > ${this.name}`;
    }
  }

  isOther() {
    return this.category === 'Other';
  }

  acceptsInput(searchTerm: string) {
    return this.isOther() || this.description.toLowerCase().includes(searchTerm.toLowerCase());
  }

  initFilterProvider() {
    if (this.filterProvider) {
      this.filterProvider.init(this);
    }
  }

  updateFilterData() {
    if (this.filterProvider) {
      this.filterProvider.loadFilterData(this);
    }
  }
}

function createCategory(category: string, commands: BaseCommand[]): Command[] {
  return commands.map(command => new Command({ ...command, category }));
}

@Injectable({
  providedIn: 'root'
})
export class CommandPaletteService {
  companyChangedSubscription: any;

  commands = [];

  constructor(
    private broadcasterService: BroadcasterService,
    private companyStateService: CompanyStateService,
    private editorService: EditorService,
    private modalService: ModalService,
    private stateService: StateService,
    private companyApiService: CompanyApiService,
    private displayApiService: DisplayApiService,
    private presentationApiService: PresentationApiService,
    private scheduleApiService: ScheduleApiService
  ) {
    this.createCommands();
  }

  createCommands() {
    this.commands = [
      ...createCategory('Alerts', this.createAlertCommands()),
      ...createCategory('Company', this.createCompanyCommands()),
      ...createCategory('Displays', this.createDisplayCommands()),
      ...createCategory('Plans', this.createPlansCommands()),
      ...createCategory('Presentations', this.createPresentationCommands()),
      ...createCategory('Schedules', this.createScheduleCommands()),
      ...createCategory('Screen Share', this.createScreenShareCommands()),
      ...createCategory('Storage', this.createStorageCommands()),
      ...createCategory('Other', this.createOtherCommands()),
    ];
  }

  loadCommandsFilterData() {
    this.commands.forEach(command => command.initFilterProvider());

    this.companyChangedSubscription = this.broadcasterService.on('risevision.company.selectedCompanyChanged', () => {
      if (this.companyStateService) {
        this.commands.forEach(command => command.updateFilterData());
      }
    });
  }

  filterCommands(searchTerm: string, otherCommandsVisible: boolean) {
    const validCommands = this.commands.filter(cmd => cmd.isAvailable(cmd) && (!cmd.isOther() || otherCommandsVisible));
    const otherCommands = this.commands.filter(cmd => cmd.isOther());
    const fuzzyConfig = { threshold: 0, all: true };

    const results = fuzzysort.go(searchTerm, validCommands, { ...fuzzyConfig, keys: ['description', 'tags'] });
    const filteredCommands = results.map(result => result.obj);

    if (otherCommandsVisible) {
      for (const otherCommand of otherCommands) {
        if (otherCommand.isAvailable(otherCommand) && filteredCommands.indexOf(otherCommand) === -1) {
          filteredCommands.push(otherCommand);
        }
      }
    }

    return filteredCommands;
  }

  filterFilterData(searchTerm: string, filterData: FilterData[]) {
    const results = fuzzysort.go(searchTerm, filterData, { threshold: 0, all: true, keys: ['description'] });

    return results.map(result => result.obj);
  }

  navigate(name: string, target: string, tags?: string) {
    return {
      name,
      tags,
      run: () => this.stateService.go(target)
    };
  }

  navigateToId(name: string, target: string, tags?: string, filterProvider?: FilterProvider) {
    const filterData = [];

    return {
      name,
      tags,
      filterData,
      filterProvider,
      run: (cmd: Command) => {
        this.stateService.go(target, cmd.userFilterData);
      }
    };
  }

  invokeFn(name: string, action: (command: Command) => void, isAvailable?: (command: Command) => boolean, tags?: string, filterProvider?: FilterProvider) {
    const filterData = filterProvider ? [] : undefined;

    return {
      name,
      tags,
      filterData,
      filterProvider,
      run: (command: Command) => action.bind(this)(command),
      isAvailable: (command: Command) => isAvailable.bind(this)(command)
    };
  }

  addPresentation() {
    this.editorService.addPresentationModal();
  }

  selectSubCompany() {
    this.modalService.showLargeModal(SelectCompanyComponent, {
      backdrop: true
    });
  }

  quickSelectSubCompany(command: Command) {
    const company = command.userFilterData;

    this.companyStateService.switchCompany(company.id);
  }

  switchToMyCompany() {
    this.companyStateService.resetCompany();
  }

  isUserCompanySelected() {
    return this.companyStateService.isUserCompanySelected();
  }

  isSubCompanySelected() {
    return !this.companyStateService.isUserCompanySelected();
  }

  isRootCompany() {
    return this.companyStateService.isRootCompanySelected();
  }

  async loadAllItems(loadFn: (params: any, cursor?: string) => any, params: any, idFieldName: string, cursor?: string) {
    let result = await loadFn(params, cursor);
    let items = result.items;

    console.log('Retrieved result:', result);

    if (!!result.cursor) {
      const newItems = await this.loadAllItems(loadFn, params, idFieldName, result.cursor);

      items = result.items.concat(newItems);
    }

    if (!items) {
      return [];
    }

    return items.map((item: any): FilterData => ({
      id: item.id,
      description: item.name,
      [idFieldName]: item.id
    }));
  }

  async loadCompaniesFilterData(cmd: Command): Promise<void> {
    const params = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'sortBy': 'name',
      'count': 250,
      'includeSubcompanies': true
    };

    if (this.isRootCompany()) {
      cmd.filterData = [];
      return;
    }

    cmd.filterData = await this.loadAllItems(this.companyApiService.getCompanies.bind(this.companyApiService), params, 'id');
    console.log('Loaded companies:', cmd.filterData);
  }

  companiesDataProvider = {
    init: (cmd: Command) => {
      // When a company is created, we automatically switch to it; that is already handled in loadCommandsFilterData
    },
    loadFilterData: (cmd: Command) => this.loadCompaniesFilterData(cmd)
  };

  async loadDisplaysFilterData(cmd: Command): Promise<void> {
    const params = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': '',
      'count': 250,
      'sort': 'name asc'
    };

    cmd.filterData = await this.loadAllItems(this.displayApiService.list.bind(this.displayApiService), params, 'displayId');
  }

  displayDataProvider = {
    init: (cmd: Command) => {
      this.broadcasterService.on('displayCreated', () => {
        setTimeout(() => {
          this.loadDisplaysFilterData(cmd);
        }, 1000);
      });
    },
    loadFilterData: (cmd: Command) => this.loadDisplaysFilterData(cmd)
  };

  async loadPresentationsFilterData(cmd: Command): Promise<void> {
    const params = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': '',
      'count': 250,
      'sort': 'name asc'
    };

    cmd.filterData = await this.loadAllItems(this.presentationApiService.list.bind(this.presentationApiService), params, 'presentationId');
  }

  presentationDataProvider = {
    init: (cmd: Command) => {
      this.broadcasterService.on('presentationCreated', () => {
        setTimeout(() => {
          this.loadPresentationsFilterData(cmd);
        }, 1000);
      });
    },
    loadFilterData: (cmd: Command) => this.loadPresentationsFilterData(cmd)
  };

  async loadSchedulesFilterData(cmd: Command): Promise<void> {
    const params = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': '',
      'count': 250,
      'sort': 'name asc'
    };

    cmd.filterData = await this.loadAllItems(this.scheduleApiService.list.bind(this.scheduleApiService), params, 'scheduleId');
  }

  scheduleDataProvider = {
    init: (cmd: Command) => {
      this.broadcasterService.on('scheduleCreated', () => {
        setTimeout(() => {
          this.loadSchedulesFilterData(cmd);
        }, 1000);
      });
    },
    loadFilterData: (cmd: Command) => this.loadSchedulesFilterData(cmd)
  };

  createAlertCommands() {
    return [
      this.navigate('Edit CAP', 'apps.displays.alerts'),
    ];
  }

  createCompanyCommands() {
    return [
      this.navigate('Add Sub-Company', 'apps.company.add', 'create new'),
      this.navigate('Add User', 'apps.user.add', 'create new'),
      this.navigate('Billing', 'apps.billing.home'),
      this.invokeFn('Change Company', this.selectSubCompany, this.isSubCompanySelected, 'select sub switch'),
      this.navigate('Licenses', 'apps.company.licenses'),
      this.invokeFn('Select Sub-Company', this.selectSubCompany, () => true, 'switch'),
      this.invokeFn('Quick Select Sub-Company', this.quickSelectSubCompany, () => !this.isRootCompany(), 'switch', this.companiesDataProvider),
      this.navigate('Settings', 'apps.company.details', 'sso saml fuse apple tv jamf claim authentication billing'),
      this.navigate('Show Users', 'apps.user.list', 'show list'),
      this.invokeFn('Switch to My Company', this.switchToMyCompany, this.isSubCompanySelected, 'select sub'),
    ];
  }

  createDisplayCommands() {
    return [
      this.navigate('Add Display', 'apps.displays.add', 'create new'),
      this.navigate('Bulk Activation', 'apps.displays.bulk-activation'),
      this.navigateToId('Edit Display', 'apps.displays.details', 'view details', this.displayDataProvider),
      this.navigate('Install Player', 'apps.displays.install'),
      this.navigate('Show Displays', 'apps.displays.list', 'list'),
    ];
  }

  createOtherCommands() {
    return [
      { name: 'Run', run: () => {} },
    ];
  }

  createPlansCommands() {
    return [
      this.navigate('Purchase', 'apps.purchase.home'),
    ];
  }

  createPresentationCommands() {
    return [
      this.invokeFn('Add Presentation', this.addPresentation, alwaysAvailable, 'create new'),
      this.navigateToId('Edit Presentation', 'apps.editor.templates.edit', 'view details', this.presentationDataProvider),
      this.navigate('Show Presentations', 'apps.editor.list', 'list'),
    ];
  }

  createScheduleCommands() {
    return [
      this.navigate('Add Schedule', 'apps.schedules.add', 'create new'),
      this.navigateToId('Edit Schedule', 'apps.schedules.details', 'view details', this.scheduleDataProvider),
      this.navigate('Show Schedules', 'apps.schedules.list', 'list'),
    ];
  }

  createScreenShareCommands() {
    return [
      this.navigate('Join a Session', 'apps.screen-sharing.home', 'screen sharing share'),
      this.navigate('Show Displays for Moderated Sessions', 'apps.screen-sharing.moderator-join', 'screen sharing share'),
      this.navigateToId('Start a Moderated Session', 'apps.screen-sharing.moderator-room', 'screen sharing share', this.displayDataProvider),
    ];
  }

  createStorageCommands() {
    return [
      this.navigate('Show Files', 'apps.storage.home', 'storage files folders upload'),
    ];
  }
}
